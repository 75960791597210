import Fingerprint2 from 'fingerprintjs2'
import { FingerPrint, FingerPrintHash } from 'types/FingerPrint'

const getFingerprint = (): Promise<any> => {
  const options = {
    NOT_AVAILABLE: 'not available',
    ERROR: 'error',
    EXCLUDED: 'excluded',
  }
  return Fingerprint2.getPromise(options)
}

const generateFingerprint = async (): Promise<any> => {
  const fingerprint = await getFingerprint().catch((err) => {
    throw new Error(`Fail generate FingerPrint ${err}`)
  })
  return fingerprint.reduce(
    (o: any, c: any) => Object.assign(o, { [c.key]: c.value }),
    {},
  )
}

const makeFingerprintHash = (fingerprint: any): string => {
  const fpValues = Object.values(fingerprint)
  return Fingerprint2.x64hash128(fpValues.join(''), 31)
}

export const getFingerprintAndHash = async () => {
  const fingerprint = await generateFingerprint()
  const fingerprintHash = makeFingerprintHash(fingerprint)
  return [fingerprint as FingerPrint, fingerprintHash as FingerPrintHash]
}
